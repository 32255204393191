/* eslint-disable quote-props */

type LabelSet = Record<string, string>;
type Labels = Record<string, string | LabelSet>;

const isLabelSet = (value: string | LabelSet): value is LabelSet => typeof value !== 'string';

let _showLabelKeys = false;
let _namespace: string | null = null;

const i18n = (
    k: string,
    returnKeyAsFallback: boolean = true,
    namespaceValue: string | null = null,
): string => {
    if (!k) return '';
    const key = k.replace(/-/g, '_');
    if (_showLabelKeys === true) return key;
    let labelSet = i18n.labels as LabelSet;
    const namespace = namespaceValue || _namespace;

    if (namespace) {
        // seet correct namespace with labels in to labelSet
        labelSet = isLabelSet(i18n.labels[namespace]) ? i18n.labels[namespace] : {};
    }
    // return the translation
    if (labelSet[key]) return labelSet[key];

    return returnKeyAsFallback ? key : '';
};

// Set default labels
i18n.labels = {} as Labels;

i18n.set = (labels: Labels): void => {
    i18n.labels = { ...i18n.labels, ...labels };
};

i18n.injectParams = (k: string, params = {}, namespaceValue: string = 'general'): string => {
    let label = i18n(k, true, namespaceValue);
    Object.entries(params).forEach(([placeholder, value]) => {
        label = label.replaceAll(placeholder, value as string);
    });
    return label;
};

i18n.setNamespace = (namespaceValue: string): void => {
    _namespace = namespaceValue;
};

i18n.setShowLabelKeys = (value: boolean): void => {
    _showLabelKeys = value;
};

export default i18n;
